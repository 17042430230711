@import "../../assets/scss/breakpoints";
@import "../../assets/scss/vars";
@import "../../assets/scss/mixins";

.container {
  width: 300px;
  @include respond-to(m) {
    width: 340px
  }
}

.choice {
  box-sizing: border-box;
  padding: 12px;
  color: $secondary_text;
  border: 1px solid $border_violet;
  border-radius: 30px;
  cursor: pointer;
  margin-bottom: 20px;
  @include transition(all, 100ms, ease-in-out);

  &:hover {
    background-color: $border_violet;
    border: 1px solid transparent;
    @include scale(1.02);
  }

  &:active {
    @include scale(1.05);
  }

  @include respond-to(m) {
    font-size: 18px;
    color: $primary_text
  }
}

