@import 'src/assets/scss/vars';
@import 'src/assets/scss/breakpoints';

.menuControl {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.menuControlIcon {
  fill: $primary_text;
  width: 24px;
  height: 24px;
}

.root {
  font-size: 0;
  line-height: 0;
}

.menuContent {
  color: $secondary_text;
}

.menuContentWrapper {
  // TODO move to reset
  box-sizing: border-box;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  width: 100%;
  background: $background_gradient;
  padding: 20px 15px;

  @include respond-to(m) {
    width: 312px;
    padding-left: 34px;
    padding-right: 34px;
  }
}

.menuClose {
  composes: menuControl
}

.menuCloseIcon {
  fill: $primary_text;
  width: 20px;
  height: 20px;
}

.menuCloseWrapper {
  margin-bottom: 20px;
}

.menuNavList {
  margin-top: -10px;
  margin-bottom: -10px;
}

.menuNavListItem {
  padding-top: 10px;
  padding-bottom: 10px;
}

.menuNavLink {
  font-size: 14px;
  line-height: 20px;
}
