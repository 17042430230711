@import "../../../assets/scss/mixins";
@import "../../../assets/scss/breakpoints";
@import "../../../assets/scss/vars";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid;
  border-image: $primary_gradient 1 0 0 0;
  box-sizing: border-box;
  max-width: 100%;
  width: 330px;
  padding-top: 10px;
  @include respond-to(m) {
    padding-top: 32px;
    width: 709px;
  }
  @include respond-to(l) {
    width: 864px;
  }
}

.price {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: $primary_text;
  margin-bottom: 11px;
  @include respond-to(m) {
    font-size: 18px;
    margin-bottom: 19px;
  }
}

.price_discount {
  color: $typography_grey;
  text-decoration: line-through;
}

.price_discount::after {
  content: " ";
}

.button, .button_wrapper {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 8px;
  max-width: 330px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  @include respond-to(m) {
    height: 50px;
  }
}

.button_wrapper {
  margin-top: 10px;
  margin-bottom: 20px;
  @include respond-to(m) {
    margin-top: 52px;
  }
}

.consents_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.consents_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.checkbox {
  @include respond-to(m) {
    text-align: center;
  }

  & label {
    font-size: 12px;
    line-height: 18px;
    @include respond-to(m) {
      font-size: 14px;
    }
  }
}

.inline_link {
  color: $primary_text;
}

.description {
  font-size: 12px;
  margin-top: 20px;
  line-height: 20px;
  max-width: 330px;
  margin-left: 10px;
  margin-right: auto;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}