// breakpoints
$small: 580px;
$medium: 1024px;
$large: 1366px;

// colors
$primary_text: #F2F3FD;
$error: #FF6060;
$secondary_text: #E2E4E7;
$typography_black: #3F404C;
$typography_grey: #828282;
$primary_violet: #8E8CF0;
$primary_border: #BDBDBD;
$border_violet: #454895;
$background_gradient: linear-gradient(319.49deg, #083A5C 15.31%, #13122A 106.96%);
$primary_gradient: linear-gradient(101.64deg, #3E1D76 -5.39%, #56CCF2 103.44%);
$secondary_gradient: linear-gradient(90.6deg, rgba(106, 77, 188, 0.5) 0.47%, rgba(242, 153, 74, 0.5) 137.94%);
$primary_border_gradient: linear-gradient(38.65deg, #4568DC 27.36%, #B06AB3 87.78%);
$primary_postQuiz_gradient: linear-gradient(180deg, #141333 0%, rgba(20, 19, 51, 0) 100%);
$background_secondary_gradient : linear-gradient(109.66deg, #FFFFFF 1.11%, rgba(255, 255, 255, 0.23) 115.23%);

// dimensions
$modal_padding: 20px;
