@import "src/assets/scss/breakpoints";
@import "src/assets/scss/vars";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  max-width: 708px;
  color: $primary_text;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  @include respond-to(m) {
    font-size: 24px;
  }
}

.img {
  margin: 30px 0;
  width: 180px;
  height: 180px;
  @include respond-to(m) {
    width: 330px;
    height: 330px;
  }
}

.button, .button_wrapper {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 8px;
  max-width: 330px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  @include respond-to(m) {
    height: 50px;
  }
}
